import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';

import BackButton from '../Navigation/BackButton';
import { useEffect, Fragment } from "react";

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

import GetFunction from "../API/getFunction";

// Keyframes pour les animations
import { keyframes } from '@emotion/react'



// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'
        
export default function Profile() {

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  //
  // Animation du badge
  //

  const status = ["Matelot","Chef de quart","Capitaine","Commandant","Amiral"]
  const upgradePoints=["0","1000","3000","7000","15000"]

  const noBoxSx = {padding : 1, display : "flex", flexDirection : "column", alignItems : "center"}
  const boxSx = {padding : 1,border : 2, borderRadius : 5, borderColor : "#f3722c", display : "flex", flexDirection : "column", alignItems : "center"}

  let buttonEffect = keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }`

  const notAnimatedSx = { width: 30, height: 30}
  const animatedSx = { width: 50, height: 50, animation: `${buttonEffect} 1.5s ease infinite`}

  const profilePicture = () => {
    if (myProfile.userPicurl) {
    return (
      <Avatar
      src={myProfile.userPicurl}  
      sx={{ width: 70, height: 70,margin:1 }}
    />
      )
    } else {
    return (
      <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 100 }} />
    );
    }
  };
  
  // Chargement du profil le plus récent
  useEffect(() => {
    GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('Profile.js -> Chargement userProfile dans le state Redux')
        dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
      } else {
        console.log('Profile.js -> Erreur dans le fetch userProfile')
      }
    })
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[])

  return (
    <Container component="main" maxWidth="sm">
      <BackButton/>
      <Box sx={{height : 70, backgroundColor : "white"}}/>
    
      <Grow in={true} direction="up">
        <Paper elevation={10}>
          <Box
            sx={{
              marginTop: 5,
              marginRight: 1,
              marginLeft: 1,
              marginBottom : 1,
              paddingTop: 2,
              paddingRight: 2,
              paddingLeft: 2,
              paddingBottom : 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor : "white",
              borderRadius : 3
            }}
          >
            {profilePicture()}
            <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
              {myProfile.userNickname}
            </Typography>
            {(myProfile.userIsHarbor ?
              <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                Profil Capitainerie
              </Typography>
              :
              null
            )}
            <p/>
            {(myProfile.userIsHarbor ?
              null
              :
              <Fragment>
                <Box width = "100%" sx={{display : "flex", flexDirection : "row", justifyContent: 'space-evenly' }}>
                  {status.map((status,index) => 
                    <Box display="flex" flexDirection="column" flex="1" key={index}>
                      <Typography variant="body2"  color={"secondary"} textAlign={'center'} fontWeight="bold">
                        {(myProfile.userGlobalStatus === status ?
                          myProfile.userGlobalPoints + " points"
                          :
                          <span>&nbsp;</span> // Synthaxe pour ajouter un espace qui soit affiché
                        )}  
                      </Typography>
                      <Box sx={(myProfile.userGlobalStatus === status ? boxSx : noBoxSx)}>
                        <Typography variant="body2" color={(myProfile.userGlobalStatus === status ? "secondary" : "primary")} sx ={{fontWeight : (myProfile.userGlobalStatus === status ? "bold" : "regular")}} textAlign ='center'>
                          {status}
                        </Typography>
                        <Avatar
                          src={"/static/status/status" + (index + 1) + ".svg"}  
                          sx={(myProfile.userGlobalStatus === status ? animatedSx : notAnimatedSx)}
                        />
                        <Avatar
                          src={"/static/usermapicon/usermapicon" + (index + 1) + ".svg"}  
                          sx={(myProfile.userGlobalStatus === status ? animatedSx : notAnimatedSx)}
                        />
                        <Typography variant="body2" color={(myProfile.userGlobalStatus === status ? "secondary" : "primary")} sx ={{fontWeight : (myProfile.userGlobalStatus === status ? "bold" : "regular")}} textAlign ='center'>
                          {upgradePoints[index]}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <p/>
                <Typography variant="body2" color={"primary"} textAlign={'center'}>
                  Pour gagner des points et acquérir des badges, soyez actifs en faisant vivre notre communauté : publiez des posts, commentez et likez ceux des autres utilisateurs !
                </Typography>
                <p/>
              </Fragment>
            )}
            <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
              {(myProfile.userIsHarbor ? "Le mot de la capitainerie": "Quelques mots sur moi")}
            </Typography>
            <Typography variant="body2" color={"primary"} textAlign={'left'}>
              {(myProfile.userDescription?myProfile.userDescription:"Vous n'avez pas rempli ce champ du formulaire")}
            </Typography>
            <p/>
            {(myProfile.userIsHarbor ?
              null
              :
              <Fragment>
                <Typography variant="body2" color={"secondary"} textAlign={'center'} fontWeight="bold">
                  Quelques mots sur mon bateau
                </Typography>
                <Typography variant="body2" color={"primary"} textAlign={'left'}>
                  {(myProfile.userBoat?myProfile.userBoat:"Vous n'avez pas rempli ce champ du formulaire")}
                </Typography>
                <p/>
              </Fragment>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography fontSize={"large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
                Notifications par mail
              </Typography>
              <FormControlLabel
                control={<Switch checked={myProfile.userMailNotification} disabled/>}
                label={myProfile.userMailNotification ?
                  <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
                    Vous recevrez un mail pour vous avertir d'une nouvelle notification
                  </Typography>
                :
                  <Typography fontSize={"small"} color={"error"} textAlign={'left'}>
                    Vous ne recevrez pas de mail en cas de notification dans l'application
                  </Typography>
                }
                />
              <p/>
              {(myProfile.userIsHarbor ?
              null
              :
              <Fragment>
                <Typography fontSize={"large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
                Partage de position
              </Typography>
              <FormControlLabel
                control={<Switch checked={myProfile.userPositionShare} disabled/>}
                label={myProfile.userPositionShare ?
                  <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
                    Position partagée. Vous pouvez voir la position des autres utilisateurs
                  </Typography>
                :
                  <Typography fontSize={"small"} color={"error"} textAlign={'left'}>
                    Position non partagée. Impossible de voir la position des autres utilisateurs
                  </Typography>}
              />
              </Fragment>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft:2,
                marginTop : 2
              }}
            >
              <Button
                onClick={() => navigate("/EditProfile")}
                variant="contained"
                endIcon={<EditIcon />}
                sx={{margin : 2}}
                >
                Modifier
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grow>
    </Container>
  );
}