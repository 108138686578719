import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import Grow from '@mui/material/Grow';
import { Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import AddLocationIcon from '@mui/icons-material/AddLocation';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useState} from 'react'

import { useNavigate, useLocation } from 'react-router-dom'
import PostFunction from '../API/postFunction';
import GetFunction from "../API/getFunction";

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'

       
export default function MapNewMarker() {

  // PARAMETRAGE
  
  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // On récupère les données passées en state du lien
  const {state} = useLocation();
  const { reportMarker, spotPolygon } = state

  const [newSpotScreenState, setNewSpotScreenState] = useState({
    spotName : "",
    isFetching : false,
    spotType : "Mouillage",
    spotPolygon : spotPolygon
  });

  function handleSubmitCreate() {
    SendNewMarker()
  };

  function handleSubmitCancel() {
    navigate(-1)
};

  const handleChangeRadio = (event) => {
    setNewSpotScreenState(prevState => ({...prevState, spotType : event.target.value}));
  };

  function SendNewMarker() {
    console.log('MapNewMarker.js -> Lancement API sendNewSpot')
    setNewSpotScreenState(prevState => ({...prevState, isFetching:true}))
    
    let newSpot = {
      spotName : newSpotScreenState.spotName,
      spotType : newSpotScreenState.spotType,
      spotLatitude : reportMarker.lat,
      spotLongitude : reportMarker.lng,
      spotPolygon : newSpotScreenState.spotPolygon
    }

    PostFunction({fetchTarget : 'sendNewSpot', fetchArgument : newSpot, token : token})
    .then(response => {
      if (response.fetchStatus === 'Ok')  {
        console.log('MapNewMarker.js -> Envoi OK')
        setNewSpotScreenState(prevState => ({...prevState, isFetching:false}))
        GetFunction({fetchTarget : 'markersArray',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapNewMarker.js -> Chargement markersArray dans le state Redux')
            dispatch({ type : "LOAD_MARKERS_ARRAY", payload:response.data})
          } else {
            console.log('MapNewMarker.js -> Erreur dans le fetch markersArray')
          }
        })
        navigate(-1)
      } else {
        console.log('MapNewMarker.js -> Envoi en échec')
        setNewSpotScreenState(prevState => ({...prevState,
          isFetching:false
        }))
        let errorMessage = "Erreur lors de la création du nouveau spot, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function handleChange(event) {
    setNewSpotScreenState(prevState => ({...prevState,[event.target.id]:event.target.value}))
  };

  function ButtonBox() {
    return(
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft:2,
        }}
      >
        <Button
            variant="contained"
            sx={{ mt: 3, ml: 2, mr:2 }}
            endIcon={<CancelIcon />}
            onClick={handleSubmitCancel}
            >
            Annuler
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ mt: 3, ml: 2, mr:2 }}
          endIcon={<AddLocationIcon />}
          onClick={handleSubmitCreate}
          disabled={(newSpotScreenState.spotName === "" || newSpotScreenState.spotType === "")}
          loading={newSpotScreenState.isFetching}
        >
          Créer
        </LoadingButton>
      </Box>
    )
  }

  return (
    <Grow in={true}>
      <Box sx={{display : "flex", flexDirection : "column", alignItems : "center", padding : 1}}>
        <Paper sx={{display : "flex", flexDirection : "column", borderRadius : 10, width : {xs : "90%", sm : "80%", md : "70%"}, backgroundColor : "white", padding : 1}} elevation={20}>
          <Typography variant="h6" color={"primary"} textAlign="center">
            Créer un marker
          </Typography>
          <Divider variant="middle" />
          <TextField
            required
            fullWidth
            id="spotName"
            label="Nom du spot"
            onChange={(event) => handleChange(event)}
            value={newSpotScreenState.spotName}
          />
          <FormControl>
            <FormLabel>Type de spot</FormLabel>
            <RadioGroup
              name="SpotTypeRadio"
              value={newSpotScreenState.spotType}
              onChange={handleChangeRadio}
            >
              <FormControlLabel value="Port" control={<Radio />} label="Port" />
              <FormControlLabel value="Mouillage" control={<Radio />} label="Mouillage" />
            </RadioGroup>
          </FormControl>              
          {ButtonBox()}
        </Paper>
      </Box>
    </Grow>
  );
}