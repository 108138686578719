import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CommentIcon from '@mui/icons-material/Comment';
import { useNavigate } from 'react-router-dom'

export default function VisiblePostPreview(props) {

  const navigate = useNavigate()
  dayjs.locale('fr')

  function DateFromNow(item) {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(item.postTimestamp,"YYYY-MM-DD HH:mm:ss").fromNow())
  }

  const authorGlobalBadge = (picurl) => {
    if (picurl) {
    return (
      <Avatar
      src={picurl}  
      sx={{ width: 15, height: 15, marginLeft : 0.5, marginRight : 1 }}
    />
      )
    } else {
    return null;
    }
  };

  function CardMediaContent(item) {
    if(item.postPicurl) {
      return (
        <CardMedia
          component="img"
          image={item.postPicurl}
          alt="Image"
          sx={{maxHeight : 30,maxWidth:50}}
        />
      )
    }
  }



  return(
    <Box sx={{display : "flex", flexDirection : "column", backgroundColor : "#fdf0e9"}} overflow = "auto">
      <List dense>
        {props.postlist.map((item, index) =>
          <ListItemButton key={item.pk.toString()} sx={{backgroundColor : (index % 2 === 1 ? "white" : "#fdf0e9")}} onClick={() => {navigate("/Comments/" + item.pk.toString())}}>
            <ListItemAvatar>
              {item.authorAvatarurl ? 
                <Avatar src={item.authorAvatarurl} sx={{ width: 40, height: 40, bgcolor:"primary"}} />
                :
                <AccountCircleIcon color="primary"  sx={{ fontSize: 40 }} />
              }
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                  {item.authorNickname}
                  <span>&nbsp;</span>
                  {authorGlobalBadge(item.authorGlobalBadge)}
                  {DateFromNow(item)}
                </Box>
              }
              secondary={
                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent : "space-between", width : "80%"}}>
                  <Typography variant="subtitle2" style={{ display: 'inline',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.postText}
                  </Typography>
                  {CardMediaContent(item)}
                </Box>
              }
            />
            <ListItemSecondaryAction>
              <Box sx={{display : "flex", flexDirection : "row"}}>
                <CommentIcon  color="primary" sx={{marginRight : 1}}/>
                <Typography  variant="body1" component="div" color="primary">
                  {item.nbComments}
                </Typography>
              </Box>
            </ListItemSecondaryAction>
          </ListItemButton>
        )}
      </List>
    </Box>
  )
}