import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import Grow from '@mui/material/Grow';
import { Paper, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import Resizer from "react-image-file-resizer";

import {useState, useEffect} from 'react'

import CameraAltIcon from '@mui/icons-material/CameraAlt';
  
import { useNavigate, useLocation } from 'react-router-dom'
import PostFunction from '../API/postFunction';
import GetFunction from '../API/getFunction';

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'
       
export default function MapNewPost() {

  // Compression des images
  const MaxWidth = 1500    // Largeur max
  const MaxHeight = 1500    // Hauteur max
  const Quality = 90      // Qualité (0-100)
  const MinWidth = 1500    // Largeur min
  const MinHeight = 1500    // Hauteur min

  const selectProfile = state => state.myProfile
  const profil = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('MapNewPost.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "HIDE_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('MapNewPost.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])
 

  // On récupère la liste de spots passée en state du lien
  const {state} = useLocation();
  const { spots } = state

  const [newPostState, setNewPostState] = useState({
    text : "",
    newPicFile : null,
    isFetching : false,
    isResizingPic : false,
    alertObject : null,
  })

  useEffect(() => {
    console.log('MapNewPost.js -> useEffect : chargemment dans le state de la liste des spots proches')
    if (spots.length >0) {
      setNewPostState(prevState => ({
        ...prevState,
        spotsList : spots
      }))
    }
   },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  const onPicChange = (event) => {
    if (event.target.files.length > 0) { // Pour éviter le cas où l'utilisateur clique sur "Annuler" dans la fenêtre
      setNewPostState(prevState => ({
        ...prevState,
        isResizingPic : true
      }))
      // Lancement de la compression de l'image de profil
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          MaxWidth,    // Largeur max
          MaxHeight,    // Hauteur max
          "JPEG",  // Format de sortie
          Quality,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Caalback function (lancée à la fin du traitelent)
            console.log('MapNewPost -> Image compressée')
            setNewPostState(prevState => ({
              ...prevState,
              isResizingPic : false,
              newPicFile : uri
            }))
          },
          "file",   // Type de sortie
          MinWidth,       // Largeur min
          MinHeight        // Hauteur min
        );
      } catch (err) {
        console.log('MapNewPost -> Echec compression image')
        let errorMessage = "Votre image n'a pas pu être traitée."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    }
  };

  const onPicDelete = () => {
    setNewPostState(prevState => ({
      ...prevState,
      newPicFile:null
    }))
  };

  const PostPicture = () => {
    if (newPostState.newPicFile) {
      return (
        <Box
          component="img"
          sx={{
            display: 'inline-flex',
            maxHeight: { xs: 70, md: 70 },
            maxWidth: { xs: 70, md: 70 },
          }}
          src={URL.createObjectURL(newPostState.newPicFile)}
        />
      )
    } else return null
  };

  const PicAddButton = () => {
    if (!newPostState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="add picture" component="label"
            type="submit"
            variant="contained"
            loading={newPostState.isResizingPic}
            >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicChangeButton = () => {
    if (newPostState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="change picture" component="label"
            type="submit"
            variant="contained"
            loading={newPostState.isResizingPic}
            >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicDeleteButton = () => {
    if (newPostState.newPicFile) {
      return (
        <Button color="error" aria-label="remove picture" component="label"
          type="submit"
          variant="contained"
          onClick={onPicDelete}
        >
          <CancelIcon />
        </Button>
      );
    }
  }
    
  function handleSubmitPost() {
    SendNewPost()
  };

  function handleSubmitCancel() {
    navigate(-1)
};

  function SendNewPost() {
    console.log('MapNewPost.js -> Lancement API sendPost')
    setNewPostState(prevState => ({...prevState, isFetching:true}))
    
    const newPostFormData = new FormData();
    if (newPostState.newPicFile) {
    newPostFormData.append("postPicurl", newPostState.newPicFile,newPostState.newPicFile.name)
    } else {
      newPostFormData.append("postPicurl",new File([], ''))
    }
    newPostFormData.append("postAuthor", profil.id)
    newPostFormData.append("postText", newPostState.text)

    newPostFormData.append("postSpot", 1)
    newPostFormData.append("postType", "Post")

    // Une request d'un serializer ne reussit pas à lire un array.
    // On va donc plutôt lui envoyer une serie de champs avec des keys "postSpotX"
    newPostState.spotsList.forEach((spot,index) => {
      newPostFormData.append("postSpots" + index, spot.pk)
    })

    PostFunction({fetchTarget : 'sendPostForm', fetchArgument : newPostFormData, token : token})
    .then(response => {
      if (response.fetchStatus === 'Ok')  {
        console.log('MapNewPost.js -> Envoi OK')
        setNewPostState(prevState => ({...prevState, isFetching:false}))

        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapNewPost.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('MapNewPost.js -> Réception du profil à jour en échec')
          }
        })
        navigate(-1)
      } else {
        console.log('Feed.js -> Envoi en échec')
        setNewPostState(prevState => ({...prevState,
          isFetching:false,
          alertObject:<Alert severity="error">Erreur lors de l'envoi de la publication</Alert>
        }))
        let errorMessage = "Erreur lors de l'envoi de la publication, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function handleChange(event) {
    setNewPostState(prevState => ({...prevState,[event.target.id]:event.target.value}))
  };

  const authorGlobalBadge = () => {
    if (profil.userGlobalBadge) {
    return (
      <Avatar
      src={profil.userGlobalBadge}  
      sx={{ width: 20, height: 20, marginRight : 1 }}
    />
      )
    } else {
    return null;
    }
  };

  return (
    <Grow in={true}>
      <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0}}>
        {/* Fond grisé qui renvoie vers la page de la carte*/}
        <Box
          onClick = {() => {navigate("/MapPage")}} 
          sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "grey", opacity : 0.5}}
        />
        <Paper sx={{position : "absolute", top : {xs :80, md : 90}, bottom : 20, left : {xs :30, sm : 60, md : 80}, right : {xs :30, sm : 60, md : 80}, backgroundColor : "white", borderRadius : 1, padding : 3}} elevation={24}>
          <Typography variant="h6" color={"primary"} textAlign="center">
            Créer une publication
          </Typography>
          <Divider variant="middle" />
          <Box sx={{display:"flex", flexDirection:"row", alignItems : "center", marginBottom : 1, marginTop : 1}}>
            <Avatar src={profil.userAvatarurl} sx={{ width: 30, height: 30, marginRight : 1 }} />
            <Typography variant="subtitle2" color="primary" sx={{ marginRight : 1}}>
              {profil.userNickname}
            </Typography>
            {authorGlobalBadge()}
          </Box>
          <TextField
            fullWidth
            multiline
            label="Que voulez-vous dire ?"
            rows={5}
            id="text"
            onChange={(event) => handleChange(event)}
            value={newPostState.text}
          />
          <Box sx={{display : "flex", flexDirection : "row"}}>
            {PostPicture()}
            <Box sx={{display:"flex", flexDirection:"column"}}>
              {PicAddButton()}
              {PicChangeButton()}
              {PicDeleteButton()}
            </Box>
          </Box>
          {newPostState.alertObject}
          <Box sx={{display : "flex", flexDirection : "column", alignItems : "flex-start", width : "100%", marginTop : 5}}>
            <Box sx={{display:"flex", flexDirection:"row", width : "100%", justifyContent: 'space-evenly'}}>
              <Button
                color="error"
                variant="contained"
                sx={{ mt: 3, ml: 2, mr:2 }}
                onClick={handleSubmitCancel}
              >
                <CancelIcon/>
              </Button>
              <LoadingButton
                variant="contained"
                color="success"
                sx={{ mt: 3, ml: 2, mr:2 }}
                onClick={handleSubmitPost}
                disabled={((!newPostState.text)&&(!newPostState.newPicFile))}
                loading={newPostState.isFetching}
              >
                <SendIcon />
              </LoadingButton>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Grow>
  );
}