import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

import ReportTypesArray from '../Mark/ReportTypesArray';

import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import Link from '@mui/material/Link';
import CommentIcon from '@mui/icons-material/Comment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Popover from '@mui/material/Popover';

import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

import PostFunction from "../API/postFunction";

import { useState } from 'react';
import { useNavigate,Link as RouterLink } from 'react-router-dom' // On importe Link en l'appelant RouterLink pour ne pas avoir de confusion avec le component Link de MUI
import { useSelector, useDispatch } from 'react-redux'

export default function Post(props) {

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const selectSubscriptionsArray = state => state.subscriptionsArray
  const subscriptionsArray = useSelector(selectSubscriptionsArray)

  const dispatch = useDispatch()

  const reportTypesArray = ReportTypesArray()

  // State permettant d'afficher l'icône correspondant à l'état de like (vrai ou faux)
  // Positionné à la valeur d'origine du post
  // On vérifie aussi si le post est déjà liké par l'utilisateur lui-même
  const [postLikes, setPostLikes] = useState({
    nbLikes : (props.postScreen === "PostWithComments" ? props.post.likes.length : props.post.nbLikes),
    iLike : (props.postScreen === "PostWithComments" ? props.post.likes.findIndex(item => item.likeAuthor === myProfile.pk) !== -1 : props.post.iLike)
  })

  // Pour gérer l'affichage du Popover avec la liste des spots
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  let displayedSpot
  if (props.postScreen === "SpotFeed") {
    displayedSpot = props.spot
  } else if (props.postScreen === "NewsFeed") {
    let firstSubscribedSpotIndex = props.post.postSpot.findIndex(postSpotItem => (subscriptionsArray.findIndex(subscriptionItem => (subscriptionItem.subscriptionSpot === postSpotItem.pk)) !== -1))
    displayedSpot = props.post.postSpot[firstSubscribedSpotIndex]
    } else {
    displayedSpot = props.post.postSpot[0]
  }
  
  let markerImgZoom
  if (displayedSpot.spotType === "Port") {
    markerImgZoom = 15
  } else if (displayedSpot.spotType === "Mouillage") {
    markerImgZoom = 12
  }

  const navigate = useNavigate()
  dayjs.locale('fr')

  function IsNotExpired(props) {
    var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
    dayjs.extend(isSameOrAfter)
    let expiration = dayjs(props.timestamp).add(props.duration,'h')
    return(dayjs(expiration).isSameOrAfter(dayjs()))
  }

  function SendReport() {
    let nextPage ="/Report/" + props.post.pk.toString() + "/" + null
    navigate(nextPage)
  }

  function ReportButton() {
    return(
      <Button
        onClick={SendReport}
      >
        <ReportProblemIcon/>
      </Button>
    )
  }

  const authorGlobalBadge = () => {
    if (props.post.authorGlobalBadge) {
    return (
      <Avatar
      src={props.post.authorGlobalBadge}  
      sx={{ width: 15, height: 15, marginRight : 1 }}
    />
      )
    } else {
    return null;
    }
  };

  function SpotChips() {
    if (props.post.postSpot.length === 1) {
      return(
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center" key={props.post.postSpot[0].pk}>
            <Link component={RouterLink} to={"/Feed/"+ props.post.postSpot[0].pk} variant="subtitle2" color="primary" underline="hover" sx={{ marginRight : 1}}>
              {props.post.postSpot[0].spotName}
            </Link>
          </Box> 
        </Box>
      )
    } else {
      return(
        <Box display="flex" flexDirection="row" alignItems="center">
          <Link component={RouterLink} to={"/Feed/"+ displayedSpot.pk} variant="subtitle2" color="primary" underline="hover" sx={{ marginRight : 1}}>
            {" " + displayedSpot.spotName}
          </Link>
          <Link component={RouterLink} onClick={handleClick} variant="subtitle2" color="primary" underline="hover" sx={{ marginRight : 1}}>
            {"+ " + (props.post.postSpot.length - 1)}
          </Link>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{display : "flex", flexDirection : "column", justifyContent : "flex-start", p: 2 }}>
              {props.post.postSpot.map(item =>
                  <Link key={item.pk} component={RouterLink} to={"/Feed/"+ item.pk} variant="subtitle2" color="primary" underline="hover" sx={{ marginRight : 1}}>
                    {item.spotName}
                  </Link>
                  
              )}
            </Box>
          </Popover>
        </Box>
      )
    }
  }

  function ReportMarkHeaderChip(props){
    if (IsNotExpired({timestamp : props.marker.post.postreportmarks.reportMarkTimestamp, duration : props.duration})) {
      return(
        <Chip size = "small" color="error" clickable={true} label="Voir sur la carte" icon={<AddLocationIcon fontSize="small"/>} onClick={() => {
          navigate("/MapPage" , {state : {zoomToSearch : {...props.marker.post.postreportmarks, nbComments : props.marker.post.comments.length}}})}}/> // Le nb de commentaires n'est pas dans le props.merket.post, donc on crée manuellement le champs pour alimenter le MarkerDetailCard pour le zoomToSearch
      )
    } else {
      return(
        <Chip size = "small" color="error" clickable={false} label="Expiré"/>
      )
    }
  }

  function ReportMarkHeader() {
    if (props.post.postType === "Report") {
      let markerTypeObject = reportTypesArray.find(item => (item.type === props.post.postreportmarks.reportMarkType))
      let markerDuration = markerTypeObject.hours
      let markerIcon = markerTypeObject.icon
      let markerLabel = markerTypeObject.label
      return(
        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}} >
          <Box
            component="img"
            sx={{
              display: 'inline-flex',
              maxHeight: 20,
              maxWidth: 20,
              marginRight : 0.5
            }}
            src={markerIcon}
          />
          <Typography
            variant="body2"
            color="error"
            fontWeight="bold"
            sx={{
              marginRight : 0.5
            }}
          >
            {markerLabel}
          </Typography>
          <ReportMarkHeaderChip marker = {props} duration = {markerDuration}/>
        </Box>
      )
    } else return null
  }
  
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.secondary.main}`
  }));


  function DateFromNow() {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(props.post.postTimestamp,"YYYY-MM-DD HH:mm:ss").fromNow())
  }

  function PostHeader() {
    let nextPageProfile ="/OtherUserProfile/" + props.post.postAuthor.toString()
    return(
      <Box sx={{ display : "flex", flexDirection : "row", marginBottom : 0.5, marginTop : 0.3, marginLeft : 0.3, alignItems : "flex-start"}}>
        <Box sx={{ display : "flex",flexDirection : "row", alignItems : "center", marginRight : 2}}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <SmallAvatar src={props.post.authorAvatarurl ? props.post.authorAvatarurl: "/static/Port-Subscribed.svg"} />
            }
          >
            <Avatar src={"https://maps.googleapis.com/maps/api/staticmap?center=" + displayedSpot.spotLatitude + "," + displayedSpot.spotLongitude + "&zoom=" + markerImgZoom + "&size=400x400&maptype=satellite&key=AIzaSyATHwwDt6IBo65JOtA0bxsJ7G8r7lk4Fdk"} />
          </Badge>
        </Box>
        <Box sx={{ display : "flex", flexDirection : "column"}}>
          <Box sx={{ display: 'inline',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
            {SpotChips()}
          </Box>
          <Box sx={{ display : "flex", flexDirection : "row", justifyContent : "space-between", alignItems : "center"}}>
            <Link component={RouterLink} to={nextPageProfile} variant="subtitle2" color={(props.post.authorIsHarbor ? "error" : "primary")} underline="hover">
              {props.post.authorNickname}
            </Link>
            {authorGlobalBadge()}
            <Typography
              variant="caption"
              color="grey"
            >
              {DateFromNow()}
            </Typography>
          </Box>
        </Box>
        <Box sx={{display : "flex", flex : 1, flexDirection : "column", alignItems : "flex-end"}}>
          {ReportButton()}
          {ReportMarkHeader()}
        </Box>
      </Box>
    )
  }

  function CardMediaContent() {
    if(props.post.postPicurl) {
      return (
        <CardMedia
          component="img"
          image={props.post.postPicurl}
          alt="Image"
          sx={{maxHeight : "80vh",maxWidth:"100%"}}
        />
      )
    }
  }

  function ToggleLike() {
    if (token) {
      let fetchArgument = {
        'likeAuthor' : myProfile.pk, //Utilisé pour que le champs soit présent et avec un bon format, mais cet id sera écrasé par le userid dans l'API
        'likePost' : props.post.pk
      }
      if (postLikes.iLike) {
        // Si l'utilisateur aime  déjà : c'est un like à supprimer
        setPostLikes(prevState => ({...prevState,
          nbLikes : prevState.nbLikes - 1,
          iLike : false
        }))
      } else {
        // Si l'utilisateur n'aime pas déjà : c'est un like à ajouter
        setPostLikes(prevState => ({...prevState,
          nbLikes : prevState.nbLikes + 1,
          iLike : true
        }))
      }
      PostFunction({fetchTarget : 'toggleLike', fetchArgument : fetchArgument, token : token})
      .then(response => {
        if(response.fetchStatus === 'Ok') {
          console.log('Post.js -> Fin chargement API toggleLike')
        } else {
          console.log('Post.js -> Chargement API toggleLike en erreur')
          let errorMessage = "Impossible de prendre en compte votre like, vérifiez votre connexion"
          dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
        }
      })
      .then(() => {
        // On lance la fonction de refresh du component parent
        console.log('Post.js -> Mise à jour du contenu')
        props.refreshFunction()
      })
    } else {
      navigate("/login")
    }
  }

  function LikeAndCommentButtons() {
    if (props.postScreen === "PostWithComments") {
      return(
        <Box sx={{display : "flex", flexDirection : "row", justifyContent : "space-between"}}>
          <Button size="small"
            onClick={ToggleLike}
          >
            {(postLikes.iLike ?
            <ThumbUpIcon  color="secondary" sx={{marginRight : 1}}/>
            :
            <ThumbUpIcon  color="primary" sx={{marginRight : 1}}/>
            )}
            {postLikes.nbLikes}
          </Button>
          {ReportButton()}
        </Box>
      )
    } else {
      let nextPage ="/Comments/" + props.post.pk.toString() 
      return(
        <Box sx={{display : "flex", flexDirection : "row", justifyContent : "space-between"}}>
          <Button size="small"
            onClick={ToggleLike}
          >
            {(postLikes.iLike ?
            <ThumbUpIcon  color="secondary" sx={{marginRight : 1}}/>
            :
            <ThumbUpIcon  color="primary" sx={{marginRight : 1}}/>
            )}
            {postLikes.nbLikes}
          </Button>
          <Button size="small"
            onClick={() => {navigate(nextPage)}}
          >
            <CommentIcon  color="primary" sx={{marginRight : 1}}/>
            {props.post.nbComments.toString() + "  commentaires"}
          </Button>
        </Box>
      )
    }
  }

  return (
    <Paper
      elevation = {2}
      sx={{
        position : "relative",
        display : "flex",
        flexDirection : "column",
        margin : 1,
        backgroundColor : (props.post.authorIsHarbor ? "#fdf0e9" : "white"),
        border : (props.post.authorIsHarbor ? 2 : 0),
        borderColor : (props.post.authorIsHarbor ? "red" : "none")
      }}
    >
      {PostHeader()}
      <Typography
        variant="body2"
        color="text.primary"
        style={{whiteSpace: 'pre-line'}} // Pour afficher les sauts de ligne
        sx={{marginBottom : 1}}
      >
        {props.post.postText}
      </Typography>
      {CardMediaContent()}
      {LikeAndCommentButtons()}
    </Paper>
  );
}