import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function Tutorial3(props) {
  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/Tutorial23", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
        onClick={() => props.goToTutorialPageFunction({page : "Page 4"})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return(
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography color={"primary"} paragraph={true}>
          Votre point de départ : la carte ! Cliquez sur les pin pour voir les communautés, vous abonner et participer.
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
          Retrouvez dans votre fil d'actualité les publications de tous vos lieux favoris.
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
          Accédez à votre messagerie privée, vos notifications et votre profil
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
          Utilisez les boutons flottants pour publier un message dans une zone, ou pour signaler un évènement localisé.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}