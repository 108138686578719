import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

import BackButton from '../Navigation/BackButton';

import {useState, useEffect} from 'react'

import GetFunction from '../API/getFunction';

import { useNavigate } from 'react-router-dom'

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'
        
export default function Account() {

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const dispatch = useDispatch()

  const [account, setAccount] = useState({
    email : null,
    newPassword1:"",
    newPassword2:"",
    isFetching : false,
    alertObject : null
  });

  function LoadAccount() {
    setAccount(prevState => ({...prevState, isFetching:true}))
    console.log('Account.js -> Chargement API getAccount')

    GetFunction({fetchTarget : 'getAccount', fetchArgument : null, token : token})
    .then(response => {
      console.log(response)
      if(response.fetchStatus === 'Ok') {
        console.log('Account.js -> Fin chargement API getAccount')
        console.log('Account.js -> Mise à jour du statut getAccount')
        setAccount(prevState => ({...prevState, email:response.data[0].email, isFetching:false}))
      } else {
        console.log('Account.js -> Impossible de charger le compte')
        setAccount(prevState => ({...prevState, isFetching:false}))
        let errorMessage = "Impossible de charger vos identifiants, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
}

  useEffect(() => {
    console.log('EditAccount.js -> useEffect')
    LoadAccount()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  function AccountScreen() {
    const navigate = useNavigate()
    if (account.email) {
    return(
      <Container component="main" maxWidth="xs">
                <Box sx={{height : 70, backgroundColor : "white"}}/>

      <Grow in={true} direction="up">
      <Paper elevation={10}>
        <Box
        sx={{
          marginTop: 5,
          marginRight: 1,
          marginLeft: 1,
          marginBottom : 5,
          paddingTop: 2,
          paddingRight: 2,
          paddingLeft: 2,
          paddingBottom : 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor : "white",
          borderRadius : 3
        }}
      >
        <BackButton/>
        <Box sx={{ mt: 3}}>
          <TextField
            fullWidth
            id="email"
            label="Adresse email"
            value={account.email}
            InputProps={{
              readOnly: true,
            }}
            sx={{marginTop : 2, marginBottom : 1}}
            />
          <TextField
            fullWidth
            id="password"
            label="Mot de passe"
            value="********"
            type="password"
            InputProps={{
              readOnly: true,
            }}
            sx={{marginTop : 1, marginBottom : 2}}
            />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft:2,
          }}
        >
          <Button
            onClick={() => navigate("/EditAccount")}
            variant="contained"
            endIcon={<EditIcon />}
            sx={{ ml: 2, mr:2 }}
          >
            Modifier
          </Button>
          </Box>
      </Box>
      </Paper>
      </Grow>
    </Container>
    )
  } else {
    return(
      <Container>
        <CircularProgress />
      </Container>
    )
  }
}

  return (
    AccountScreen()
  );
}