import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function Tutorial22() {

  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/Tutorial21", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
      onClick={() => navigate ("/Tutorial23", {replace : true})}
      variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
        <Typography color={"primary"} paragraph={true}>
          ... au mouillage...
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - Salut les voisins. Vous savez si on est abrités du vent cette nuit ?
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - Hola le mouillage ! Je prévois de venir mouiller ce soir, il n'y a pas trop de houle ?
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - Bonjour les voisins. Vous auriez un tire-bouchon à me prêter ?
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - J'ai fait du snorkeling ce matin, il y avait des super poissons au sud de la calanque !
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}