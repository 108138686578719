export default function DeleteFunction(props) {

  let urlBase = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:8000'
  : 'https://api.radioponton.fr'

  let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Token ' + props.token,
  }

  let url = ""

  console.log('deleteFunction '+ props.fetchTarget +' -> Lancement Fetch')
  switch (props.fetchTarget) {
    case 'deleteSubscription':
      url = urlBase + '/subscription/' + props.fetchArgument.pk + '/'
      break;
    default:
      console.log('deleteFunction '+props.fetchTarget+' -> fetchTarget inconnu')
  }

  return fetch(url,{
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(props.fetchArgument)
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Response pas Ok')
    } else {
      console.log('deleteFunction '+props.fetchTarget+'-> Fin du fetch OK')
      return({fetchStatus:'Ok'})
    }
  })
  .catch((error) => {
    if (error === 'Response pas Ok ') {
      console.log('deleteFunction '+props.fetchTarget+'-> Réponse non attendue du fetch')
    } else {
      console.log('deleteFunction '+props.fetchTarget+'-> Erreur pendant le Fetch')
    }
    return({fetchStatus:'Error'})
  })
}
