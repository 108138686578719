import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Post from './Post'
import GetFunction from '../API/getFunction';

//DECLARATION MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';

import BackButton from '../Navigation/BackButton';

// DECLARATIONS REACT ROUTER
import { useParams, useNavigate } from 'react-router-dom'

// DECLARATIONS STORE

export default function Feed() {

  const selectToken = state => state.token
  const token = useSelector(selectToken)
        
  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [feedState, setFeedState] = useState({
    feedListData : null,
    feedList : null,
    feedSubscription: 0,
    isFetchingFeed: true,
    isFetchingSubscriptions: true,
  })

  // On récupère le spotId en paramètre du lien
  let {spotId} = useParams()

  const [spot, setSpot] = useState()

  // pull-to-refresh : states pour conserver le point de départ + conserver la distance de pull
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Fonction pour forcer le refresh
  const RefreshFeed = () => {
    // Récupération du nouveau feed
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'spotFeedContent', fetchArgument:spotId, token:token})
    .then(response => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if(response.fetchStatus === 'Ok') {
        console.log('Feed.js -> Mise à jour du statut feedListData')
        setFeedState(prevState => ({...prevState, feedListData : response.data})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
      } else {
        console.log('Feed.js -> Impossible de charger le contenu du feed')
      }
    })
  };

  // Fonction pour gérer le pullStart
  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
    console.log('PULLSTART')
    console.log(screenY)
  };

  // Fonction pour gérer le pull
  const pull = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    setPullChange(pullLength);
    console.log('PULL')
    console.log(screenY)
    console.log('->pullLength  :')
    console.log(pullLength)
  };

  // Fonction pour gérer le endPull
  const endPull = (e) => {
    if (pullChange > 220) {
      RefreshFeed()
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
    console.log('ENDPULL')
    console.log('->pullChange  :')
    console.log(pullChange)
  };

  // Mise en place et suppression des event listeners pour gérer le pull-to-refresh
  useEffect(() => {
    window.addEventListener("touchstart", pullStart); // L'écran est touché
    window.addEventListener("touchmove", pull);       // Mouvement du doigt
    window.addEventListener("touchend", endPull);     // L'écran est lâché
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });
  
  // Fonction qui contruit le contenu du fil (améliore les performances : évite de recalculer
  // le contenu du fil à chaque render, car il y a bcp de render avec le PullToRefresh)
  function BuildFeedList(props) {
    if (props.feedContent.length > 0) {
      return(
        props.feedContent.map((item) => 
          <Post
            key={item.pk.toString()}
            postScreen="SpotFeed"
            post={item}
            spot={spot}
            refreshFunction={RefreshFeed}
          />
        )
      )
    } else {
      return(
        <Box
        >
          <Typography align="center" variant="h5" >
            Il n'y a pas encore de contribution pour ce lieu...<p/>
            <p/>...soyez le premier à poster une contribution !
          </Typography>
        </Box>
      )
    }
  }

  // Fonction de chargement du contenu du feed, lancée dans le useEffect
  function LoadFeedContent() {
    console.log('Feed.js -> Chargement API spotFeedContent')
    setFeedState(prevState => ({...prevState, isFetchingFeed : true})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
    GetFunction({fetchTarget : 'spotFeedContent', fetchArgument:spotId, token:token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('Feed.js -> Fin chargement API spotFeedContent')
        console.log('Feed.js -> Mise à jour du statut feedListData')
        setFeedState(prevState => ({...prevState, feedListData : response.data, isFetchingFeed : false})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
      } else {
        console.log('Feed.js -> Impossible de charger le contenu du feed')
        setFeedState(isFetchingFeed => false) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre

        let errorMessage = "Impossible de trouver les conversation de ce lieu contenu, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })

    console.log('Feed.js -> Chargement API spotSubscriptionsCount')
    GetFunction({fetchTarget : 'spotSubscriptionsCount', fetchArgument : spotId, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('Feed.js -> Fin chargement API spotSubscriptionsCount')
        console.log('Feed.js -> Mise à jour du statut feedSubscription')
        setFeedState(prevState => ({...prevState, feedSubscription : response.data[0].nbSubscriptions, isFetchingSubscriptions : false}))
      } else {
        console.log("Feed.js -> Impossible de charger le nombre d'abonnés du feed")
        setFeedState(prevState => ({...prevState, isFetchingSubscriptions :false}))
        let errorMessage = "Impossible de trouver le nombre d'abonnés à ce lieu contenu, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function ViewFeedMembers() {
    if (feedState.feedSubscription>0)
    {
      let nextPage = "/FeedMembers/"
      navigate(nextPage, {state : {spot : spot}})
    }
  }

  useEffect(() => {
    console.log('Feed.js -> useEffect : chargement du contenu du feed')
    LoadFeedContent()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[spotId]  // Syntaxe pour que le hook soit exécuté quand on change de spotId : pour permettre de naviguer de la page d'un spot à un autre avec les chips
  );   

  useEffect(() => {
    console.log('Feed.js -> useEffect : génération du contenu formaté du feed')
    // On ne crée les objets <Post> que si on a terminé de récupérer les données du spot
    if (spot) {
      if (feedState.feedListData) {
        setFeedState(prevState => ({...prevState, feedList : BuildFeedList({feedContent : feedState.feedListData})})) // Syntaxe permettant f'éviter qu'un des deux fetch annule la modification simultanée de l'autre
      }
    }
    },[spot, feedState.feedListData]  // Syntaxe pour que le hook soit exécuté quand on change de spotId : pour permettre de naviguer de la page d'un spot à un autre avec les chips
  );   

  useEffect(() => {
    console.log('Feed.js -> useEffect : identification des données du spot')
    if (markersArray.length > 0) {
      let screenSpot = markersArray.find(marker => (marker.pk.toString() === spotId.toString()))
      let markerImgZoom
      if (screenSpot.spotType === "Port") {
        markerImgZoom = 15
      } else if (screenSpot.spotType === "Mouillage") {
        markerImgZoom = 12
      }
      setSpot({...screenSpot, markerImgZoom : markerImgZoom})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[spotId, markersArray]  // Syntaxe pour que le hook soit exécuté quand on change de spotId : pour permettre de naviguer de la page d'un spot à un autre avec les chips
  );   

  function NewPostButton() {
    return (
        <Button
          onClick={() => navigate("/FeedNewPost" , {state : {spot : spot}})}
          variant="contained"
          endIcon={<EditIcon />}
          sx={{ mt: 1, mb: 1 }}
        >
          Ecrire
        </Button>
    );
  }


  function SpotFeed() {
    if (!feedState.isFetchingFeed & !feedState.isFetchingSubscriptions & (spot !== null)) {
      return(
        // Le Fade sert à matérialiser le passage d'un feed à un autre
        <Fade in={true} timeout={500}>
          <Box sx={{display : "flex", flexDirection : "column", backgroundColor : "white"}} height="100%"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
            <Box sx={{height : 70}}/>
            <Box sx={{display:"flex", flexDirection:"row", paddingRight : {xs : 2, md : 5}, alignItems:"center", backgroundColor : "white"}}>
              <Box>
                <Avatar
                  src={"https://maps.googleapis.com/maps/api/staticmap?center=" + spot.spotLatitude + "," + spot.spotLongitude + "&zoom=" + spot.markerImgZoom + "&size=400x400&maptype=satellite&key=AIzaSyATHwwDt6IBo65JOtA0bxsJ7G8r7lk4Fdk"}  
                  sx={{ width: {xs : 50, sm : 60, md : 70}, height: {xs : 50, sm : 60, md : 70}, margin : 1}}
                />
              </Box>
              <Box sx={{flex:1, flexDirection:"column", margin : 1}}>
                <Typography sx={{fontWeight : "bold",fontSize : {xs : 16, sm : 18, md : 20}, textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }} color={"primary"} onClick={() => navigate("/MapPage" , {state : {zoomToSearch : spot}})} >
                  {spot.spotName}
                </Typography>
                <Button size="small"
                  onClick={ViewFeedMembers}
                >
                    {feedState.feedSubscription.toString() + " abonné(es)"}
                </Button>
              </Box>
              <Box>
                {NewPostButton()}
              </Box>
            </Box>
            <BackButton/>
            <Box sx={{ flex : 1, paddingBottom : 2}} overflow = "auto">
              <Container sx={{display : "flex", height : Math.min(pullChange,50+Math.max(0,pullChange-50)/5)}}>
                <Box sx={{ flex : 1}}/>
                {(isRefreshing || pullChange > 100) ? <CircularProgress/>:null}
                <Box sx={{ flex : 1}}/>
              </Container>
              {feedState.feedList}
            </Box>
          </Box>
        </Fade>
      )
    } else {
      return (
          <Box sx={{display : "flex", flexDirection : "column"}} width="100%" height="100%"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
            <Box sx={{display:"flex", flexDirection:"row", paddingRight : {xs : 0 , sm : 2, md : 5}, alignItems:"center"}}>
              <Box sx={{flex:1}}>
                  <Skeleton variant="circular" width={70} height={70} marginleft={1} marginright={1} />
              </Box>
              <Box sx={{flex:12}}>
                <Skeleton variant="text" sx={{ fontSize: '6rem' }} />
              </Box>
              <BackButton/>
            </Box>
            <Box sx={{ display : "flex", flexDirection : "column"}}>
              <Skeleton variant="rounded" width={"90%"} height={100} sx={{padding : 3, margin : 3}}/>
              <Skeleton variant="rounded" width={"90%"} height={100} sx={{padding : 3, margin : 3}}/>
              <Skeleton variant="rounded" width={"90%"} height={100} sx={{padding : 3, margin : 3}}/>
            </Box>
          </Box>
      )
    }
  }

  return(
    SpotFeed()
  );
}