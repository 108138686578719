import { useSelector, useDispatch } from 'react-redux'
import {useState, useEffect} from 'react'

import GetFunction from '../API/getFunction';
import PatchFunction from '../API/patchFunction';
       
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Paper from '@mui/material/Paper';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Fade from '@mui/material/Fade';

import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

import Badge from '@mui/material/Badge';

import { useNavigate } from 'react-router-dom'

export default function ChatList() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectChatHeadersArray = state => state.chatHeadersArray
  const chatHeadersArray = useSelector(selectChatHeadersArray)

  // state du contenu des messages
  const [messageList, setMessageList] = useState()

  // Hook qui affiche la carte à chaque mount
  useEffect(() => {
    console.log('ChatList.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "HIDE_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('ChatList.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])
 

  // Hook qui contruit le contenu de la liste à chaque fois que le contenu change
  // (améliore les performances : évite de recalculer le contenu de la liste à chaque render,
  // car il y a bcp de render avec le PullToRefresh)
  useEffect(() => {
    if (chatHeadersArray) {
      // On crée une copie triée de la liste des headers
      const sortedChatHeadersArray = [].concat(chatHeadersArray).sort((a,b) => a.lastMessageTimestamp < b.lastMessageTimestamp ? 1:-1)
      setMessageList(
        <List dense>
          {sortedChatHeadersArray.map((item, index) =>
            <ListItemButton onClick={() => OnChatClickFunction(item)} divider alignItems="flex-start" key={item.pk.toString()} sx={{backgroundColor : (index % 2 === 1 ? "white" : "#fdf0e9")}}>
              <ListItemAvatar>
                {AvatarUnreadMessagesIndicator(item)}
              </ListItemAvatar>
              <ListItemText
                primary={ChatlistText(item)}
                secondary={DateFromNow(item)}
              />
            </ListItemButton>
          )}
        </List>
      )
    } else {
      setMessageList(
        <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
          Pas de conversation en cours
        </Typography>
      )
    }
  }, [chatHeadersArray])

  // pull-to-refresh : states pour conserver le point de départ + conserver la distance de pull
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState();
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Fonction pour forcer le refresh
  const RefreshChatList = () => {
    // Récupération du nouveau chatHeadersArray
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'chatHeadersArray', fetchArgument : null, token : token})
    .then(response => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if (response.fetchStatus === 'Ok') {
        console.log('ChatList.js -> Fin chargement API mise à jour chatHeadersArray')
        console.log('ChatList.js -> Chargement chatHeadersArray dans le state Redux')
        dispatch({ type : "LOAD_CHAT_HEADERS_ARRAY", payload : response.data})
      } else {
        console.log('ChatList.js -> Erreur du fetch : on ne fait rien')
      }
    })
  }

  // Fonction pour gérer le pullStart
  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
    console.log('PULLSTART')
    console.log(screenY)
  };

  // Fonction pour gérer le pull
  const pull = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    setPullChange(pullLength);
    console.log('PULL')
    console.log(pullLength)
  };

  // Fonction pour gérer le endPull
  const endPull = (e) => {
    if (pullChange > 220) {
      RefreshChatList()
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
    console.log('ENDPULL')
    console.log(pullChange)
  };

  // Mise en place et suppression des event listeners pour gérer le pull-to-refresh
  useEffect(() => {
    window.addEventListener("touchstart", pullStart); // L'écran est touché
    window.addEventListener("touchmove", pull);       // Mouvement du doigt
    window.addEventListener("touchend", endPull);     // L'écran est lâché
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });

  function OnChatClickFunction(item) {
    let subscriptionUpdate = {
      subscriptionUnreadMessages: 0
    }
    PatchFunction({fetchTarget : 'chatroomsubscriptionread', fetchObjectId:item.chatroomsubscriptions[0].id, fetchArgument : subscriptionUpdate, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('ChatList.js -> Fin chargement API chatroomsubscriptionread')
        console.log('ChatList.js -> Mise à jour du statut Redux')
        GetFunction({fetchTarget : 'chatHeadersArray', fetchArgument : null, token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('ChatList.js -> Chargement notificationsArray dans le state Redux')
            dispatch({ type : "LOAD_CHAT_HEADERS_ARRAY", payload : response.data})
          } else {
            console.log('ChatList.js -> Impossible de mettre à jour les pastilles de nouveaux messages')
            let errorMessage = "Impossible de mettre à jour la liste de vos notifications, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
        })
      } else {
        console.log('ChatList.js -> Impossible de mettre à jour les pastilles de nouveaux messages')
        let errorMessage = "Impossible de mettre à jour la liste de vos notifications, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
    let nextPage = "/Chat/" + item.chatroomsubscriptions[0].subscriptionChatroom + "/" + item.chatroomsubscriptions[0].subscriptionUser
    navigate (nextPage)
  }

  function DateFromNow(item) {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(item.lastMessageTimestamp,"YYYY-MM-DD HH:mm:ss").fromNow())
  }

  function AvatarUnreadMessagesIndicator(item) {
    if (item.chatroomsubscriptions[0].subscriptionUnreadMessages !== 0) {
      return(
        <Badge badgeContent={item.chatroomsubscriptions[0].subscriptionUnreadMessages} color="error">
          {UserAvatar(item)}
        </Badge>
        )
    } else {
      return(
          UserAvatar(item)
        )
    }
  }

  function UserAvatar(item) {
    if (item.chatroomsubscriptions[0].userAvatarurl) {
      return (
        <Avatar
          src={item.chatroomsubscriptions[0].userAvatarurl}  
          sx={{ width: 50, height: 50 }}
        />
      )
    } else {
      return (
        <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 50 }} />
      );
    }
  };

  function ChatlistText(item) {
    if (item.chatroomsubscriptions[0].subscriptionUnreadMessages !== 0) {
      return(
        <Typography fontWeight={'bold'}>
          {item.chatroomsubscriptions[0].userNickname}
        </Typography>
      )
    } else {
      return(
        <Typography>
          {item.chatroomsubscriptions[0].userNickname}
        </Typography>
      )
    }
  }

  return (
    <Fade in={true} timeout={200}>
      <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0}}>
        {/* Fond grisé qui renvoie vers la page de la carte*/}
        <Box
          onClick = {() => {navigate("/MapPage")}} 
          sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "grey", opacity : 0.5}}
        />
        <Paper sx={{position : "absolute", top : {xs :80, md : 90}, bottom : 20, left : 20, right : 20, backgroundColor : "white", borderRadius : 1}} elevation={24}>
          <Box sx={{position : "absolute", top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {navigate("/MapPage")}}>
            <IconButton color="secondary">
              <HighlightOffIcon fontSize = "large"/>
            </IconButton>
          </Box>
          <Box sx={{display : "flex", flexDirection : "column", height : "100%", width : "100%"}}>
            <Container sx={{display : "flex", height : Math.min(pullChange,50+Math.max(0,pullChange-50)/5)}}>
              <Box sx={{ flex : 1}}/>
              {(isRefreshing || pullChange > 100) ? <CircularProgress/>:null}
              <Box sx={{ flex : 1}}/>
            </Container>
            <Box sx={{display : "flex", width : "100%", margin : 2}}>
              <Typography variant="h5" fontWeight = "bold" color="primary">
                Mes conversations
              </Typography>
            </Box>
            <Box sx={{display : "flex", flexDirection : "column", width : "100%"}} overflow = "auto">
              {messageList}
            </Box>
          </Box>
        </Paper> 
      </Box>
    </Fade>
  )
}