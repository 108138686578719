import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackButton from '../Navigation/BackButton';

import GetFunction from '../API/getFunction';

import {useState} from 'react'
import {useDispatch } from 'react-redux'

import { useNavigate } from 'react-router-dom'

export default function Register() {

  const [profil, setProfil] = useState({
    userNickname:"",
    userEmail:"",
    newPassword1:"",
    newPassword2:"",
    userMailNotification:true,
    userPositionShare:true,
    isNicknameUnique:true,
    isEmailUnique:true,
    isFetching : false,
    isRegistered: false,
    alertObject : null
  });

  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const handleSubmitRegister = () => {
    Register()
  };

  const handleSubmitCancel = () => {
    navigate("/")
  };

  function userNicknameError() {
    if (profil.isNicknameUnique) {
      return ({error:false,message:""})
    } else {
      return ({error:true,message:"Pseudo déjà utilisé"})
    }
  }

  function userEmailError() {
    if (profil.userEmail) {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(profil.userEmail)) {
        if (profil.isEmailUnique) {
          return ({error:false,message:""})
        } else {
          return ({error:true,message:"Adresse mail déjà utilisée"})
        }
      } else {
        return ({error:true,message:"Adresse mail invalide"})
      } 
    } else {
      return ({error:false,message:""})
    }
  }

  function newPasswordError() {
    if (profil.newPassword1 !== profil.newPassword2) {
      return ({error:true,message:"Les deux mots de passe saisis ne sont pas identiques"})
    } else {
      return ({error:false,message:""})
    } 
  }

  const handleChange = (event) => {
    setProfil({...profil,[event.target.id]:event.target.value})

    // Fetch recherche unicité du userNickname
    if (event.target.id === "userNickname") {
      GetFunction({fetchTarget : 'checkNickname', fetchArgument : event.target.value, token : null})
      .then(response => {
        if(response.fetchStatus === 'Ok') {
          if (response.data.length > 0) {
            setProfil(prevState => ({...prevState, isNicknameUnique:false}))
          } else {
            setProfil(prevState => ({...prevState, isNicknameUnique:true}))
          }
        }
      })
    }

    // Fetch recherche unicité du email (on vérifie que c'est un email correct avant de lancer l'API !)
    if ((event.target.id === "userEmail")&&(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value))) {
      GetFunction({fetchTarget : 'checkEmail', fetchArgument : event.target.value, token : null})
      .then(response => {
        if(response.fetchStatus === 'Ok') {
          if (response.data.length > 0) {
            setProfil(prevState => ({...prevState, isEmailUnique:false}))
          } else {
            setProfil(prevState => ({...prevState, isEmailUnique:true}))
          }
        }
      })
    }
  };

  const handleChangeSwitch = (event) => {
    setProfil({...profil,[event.target.id]:event.target.checked})
  };

  function ButtonBox() {
    if (profil.isRegistered) {
      return(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft:2,
            marginTop : 2
          }}
        >
          <Button
              variant="contained"
              sx={{margin : 2}}
              endIcon={<AccountCircleIcon />}
              onClick={() => {navigate("/Login")}}
            >
              Se connecter
          </Button>
        </Box>
      )
    } else {
      return(
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft:2,
            marginTop : 2
          }}
        >
          <Button
            variant="contained"
            sx={{margin : 2}}
            endIcon={<CancelIcon />}
            onClick={handleSubmitCancel}
            color={"error"}
          >
            Annuler
          </Button>
          <LoadingButton
            variant="contained"
            sx={{margin : 2}}
            endIcon={<AccountCircleIcon />}
            onClick={handleSubmitRegister}
            disabled={userEmailError().error || userNicknameError().error || newPasswordError().error || !profil.userEmail || !profil.userNickname || !profil.newPassword1}
            loading={profil.isFetching}
            color={"success"}
          >
            S'inscrire
          </LoadingButton>
        </Box>
      )
    }
  }

  function Register() {
    console.log('Register.js -> Chargement API Register')
    setProfil(prevState => ({...prevState, isFetching:true}))
    let myNewProfile = {
      username:profil.userEmail,
      email:profil.userEmail,
      password:profil.newPassword1,
      userNickname:profil.userNickname,
      userBoat:null,
      userDescription:null,
      userMailNotification:profil.userMailNotification,
      userPositionShare:profil.userPositionShare,
    }

    let urlBase = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8000'
    : 'https://api.radioponton.fr'
  
    let url = urlBase + '/register/'

    let headers = {
      'Content-Type': 'application/json',
    }

    fetch(url,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify(myNewProfile)
    })
    .then((response) => {
      if (!response.ok) {
        console.log("Register.js -> Register a renvoyé un code d'erreur")
        console.log(response)
        setProfil(prevState => ({...prevState,
          isFetching : false,
        }))
        return response
      } else {
        console.log('Register.js -> Register réussi')
        setProfil(prevState => ({...prevState,
          isFetching : false,
          isRegistered: true,
          alertObject :<Alert severity="success">Compte créé, vous pouvez maintenant vous connecter et compléter votre profil</Alert>
        }))
        return 'OK'
      }
    })
    .catch((error) => {
      console.log('Register.js -> Erreur inconnue')
      console.log (error)
      setProfil(prevState => ({...prevState,
        alertObject :<Alert severity="error">Erreur lors de la création du compte</Alert>,
        isFetching : false
      }))
  })
  }

  return (
    <Container component="main" maxWidth="xs">
      <BackButton/>
      <Slide in={true} direction="up">
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
            Créez un compte pour accéder à toutes les fonctionnalités
          </Typography>
          <TextField
            sx={{marginTop : 3}}
            required
            fullWidth
            id="userNickname"
            label="Nom d'utilisateur (public)"
            error={userNicknameError().error}
            helperText={userNicknameError().message}
            onChange={(event) => handleChange(event)}
            value={profil.userNickname}
            disabled={profil.isRegistered}
          />
          <TextField
            sx={{marginTop : 3}}
            required
            fullWidth
            id="userEmail"
            label="Adresse mail"
            error={userEmailError().error}
            helperText={userEmailError().message}
            onChange={(event) => handleChange(event)}
            value={profil.userEmail}
            disabled={profil.isRegistered}
          />
          <TextField
            sx={{marginTop : 3}}
            required
            fullWidth
            id="newPassword1"
            label="Mot de passe"
            error={newPasswordError().error}
            helperText={newPasswordError().message}
            onChange={(event) => handleChange(event)}
            value={profil.newPassword1}
            type="password"
            disabled={profil.isRegistered}
          />
          <TextField
            sx={{marginTop : 3}}
            required
            fullWidth
            id="newPassword2"
            label="Confirmer le mot de passe"
            error={newPasswordError().error}
            helperText={newPasswordError().message}
            onChange={(event) => handleChange(event)}
            value={profil.newPassword2}
            type="password"
            disabled={profil.isRegistered}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography fontSize={"large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
              Notifications par mail
            </Typography>
            <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
              Nous vous préviendrons par mail chaque fois qu'un utilisateur vous enverra un message ou réagira à une de vos publications.
              Votre adresse mail ne sera JAMAIS utilisée dans un but commercial, et ne sra JAMAIS communiquée à qui que ce soit.
            </Typography>
            <FormControlLabel
              sx={{marginTop : 1}}
              control={<Switch checked={profil.userMailNotification} onChange={handleChangeSwitch} color="primary" id="userMailNotification"/>}
              label={profil.userMailNotification ?
                <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
                  Vous recevrez un mail pour vous avertir d'une nouvelle notification
                </Typography>
              :
                <Typography fontSize={"small"} color={"error"} textAlign={'left'}>
                  Vous ne recevrez pas de mail en cas de notification dans l'application
                </Typography>
              }
              disabled={profil.isRegistered}
            />
            <p/>
            <Typography fontSize={"large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
              Partage de position
            </Typography>
            <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
              En choisissant de partager votre position, vous visualisez les autres utilisateurs sur la carte et vous pouvez disloguer avec eux.
              Vous êtes également notifiés quand une publication a lieu près de votre position.
              Les autres utilisateurs pourront voir votre position, mais pas de panique ! Votre position ne sera jamais affichée lorsque vous n'êtes pas dans une zone de navigation (personne ne vous verra chez vous assis dans votre canapé !). Et les positions sont supprimées de la carte si elles ne sont pas mises à jour pendant 24h.
            </Typography>
            <FormControlLabel
              control={<Switch checked={profil.userPositionShare} onChange={handleChangeSwitch} color="primary" id="userPositionShare"/>}
              label={profil.userPositionShare ?
                <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
                  Position partagée. Vous pouvez voir la position des autres utilisateurs
                </Typography>
              :
                <Typography fontSize={"small"} color={"error"} textAlign={'left'}>
                  Position non partagée. Impossible de voir la position des autres utilisateurs
                </Typography>
              }
              disabled={profil.isRegistered}
            />
          </Box>
          {profil.alertObject}
          {ButtonBox()}
        </Box>
      </Slide>
    </Container>
  );
}