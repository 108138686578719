import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function Tutorial23() {

  const navigate = useNavigate()

  function PreviousButton() {
    return (
      <Button
        onClick={() => navigate ("/Tutorial22", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
        onClick={() => navigate("/MapPage" , {replace : true, state : {tutorial : "Page 3"}})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
        <Typography color={"primary"} paragraph={true}>
          ... ou chez soi, loin de son port d'attache...
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - Hello le port ! Ca a soufflé fort cette nuit ?
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - Salut les voisins. J'ai mon bateau au port, quelqu'un peut passer voir si mes amarres sont toujours en place ?
          </Typography>
          <Typography color={"primary"} paragraph={true}>
          - Je viens bricoler ce week-end sur mon bateau, qui sera au port pour un apéro ?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}