import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

export default function TutorialStart() {

  const navigate = useNavigate()

  function NextButton() {
    return (
      <Button
        onClick={() => navigate ("/Tutorial21", {replace : true})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
        <Typography color={"primary"} paragraph={true}>
            NauticTalk - dites NauTicToc si vous préférez - c'est l'appli qui permet aux marins de communiquer !
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}
