import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackButton from '../Navigation/BackButton';

import {useState} from 'react'

// DECLARATIONS REACT-ROUTER
import { useNavigate } from 'react-router-dom'

// DECLARATION REDUX
import { useDispatch } from 'react-redux'


export default function Login() {

  const [loginInfo, setLoginInfo] = useState({
    userEmail:"",
    password:"",
    isFetching : false,
    alertObject : null
  });

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSubmitLogin = () => {
    Login()
  };

  const handleSubmitRegister = () => {
    navigate("/Register", {navigate : true})
  };


  const handleChange = (event) => {
    setLoginInfo(prevState => ({...prevState,[event.target.id]:event.target.value}))
  };

  function userEmailError() {
    if (!loginInfo.userEmail) {
      return ({error:true,message:"Votre adresse mail est obligatoire"})
    } else {
      return ({error:false,message:""})
    }
  }

  function passwordError() {
    if (!loginInfo.password) {
      return ({error:true,message:"Votre mot de passe est obligatoire"})
    } else {
      return ({error:false,message:""})
    } 
  }

  function Login() {
    setLoginInfo(prevState => ({...prevState,isFetching:true}))
    console.log('Login.js -> Chargement API login')
    let loginObject = {
      username:loginInfo.userEmail,
      password:loginInfo.password
    }
    let urlBase = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8000'
    : 'https://api.radioponton.fr'
  
    let url = urlBase + '/login/'

    let headers = {
      'Content-Type': 'application/json',
    }
      fetch(url,{
      method: 'POST',
      headers: headers,
      body: JSON.stringify(loginObject)
    })
    .then((response) => {
      return response.json()
    })
    .then((response) => {
      console.log('Login.js -> Response reçue')
      if (!response.token) {
        console.log("Login.js -> Login a renvoyé un code d'erreur")
        setLoginInfo(prevState => ({...prevState,
          isFetching:false,
          alertObject:<Alert severity="error">Nous n'avons pas pu vous identifier</Alert>
        }))
      } else {
        console.log('Login.js -> Login réussi')
        setLoginInfo(prevState => ({...prevState,
          isFetching:false,
          alertObject:null
        }))
        let token = response.token
        console.log('Login.js -> Token fourni par le serveur : ',token)
        dispatch({ type : "LOAD_TOKEN", payload:token})
        localStorage.setItem('NAUTILUS_TOKEN', JSON.stringify(token));
        navigate("/", { replace: true });
      }
    })
    .catch((error) => {
      console.log('Login.js -> Erreur inconnue')
      setLoginInfo(prevState => ({...prevState,
        isFetching:false,
        alertObject:<Alert severity="error">Nous n'avons pas pu vous identifier</Alert>
      }))
  })
  }

  function ForgottenPasswordButton() {
    let passwordResetUrl = process.env.NODE_ENV === 'development' 
    ? 'http://localhost:8000/password_reset/'
    : 'https://api.radioponton.fr/password_reset/'

    return(
      <Typography fontWeight={'bold'} textAlign={'center'}>
        <a href={passwordResetUrl}>
          Mot de passe oublié
        </a>
      </Typography>
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <BackButton/>
      <Slide in={true} direction="up">
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography fontSize={"x-large"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
            Connectez-vous pour accéder à toutes les fonctionnalités
          </Typography>
          <TextField
            sx={{marginTop : 3}}
            required
            fullWidth
            id="userEmail"
            label="Votre adresse email"
            error={userEmailError().error}
            helperText={userEmailError().message}
            onChange={(event) => handleChange(event)}
            value={loginInfo.userEmail}
          />
          <TextField
            sx={{marginTop : 3}}
            required
            fullWidth
            id="password"
            label="Votre mot de passe"
            error={passwordError().error}
            helperText={passwordError().message}
            onChange={(event) => handleChange(event)}
            value={loginInfo.newPassword1}
            type="password"
          />
          {loginInfo.alertObject}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft:2,
              marginTop : 2
            }}
          >
            <Button
              sx={{margin : 2}}
                variant="contained"
                onClick={handleSubmitRegister}
              >
                Créer un compte
            </Button>
            <LoadingButton
              sx={{margin: 2}}
              variant="contained"
              onClick={handleSubmitLogin}
              disabled={userEmailError().error || passwordError().error}
              loading={loginInfo.isFetching}
            >
              Se connecter
            </LoadingButton>
          </Box>
          {ForgottenPasswordButton()}
        </Box>
      </Slide>
    </Container>
  );
}