import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import PostFunction from "../API/postFunction";

import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

// DECLARATIONS REACT ROUTER
import { useNavigate,Link as RouterLink } from 'react-router-dom' // On importe Link en l'appelant RouterLink pour ne pas avoir de confusion avec le component Link de MUI

// DECLARATIONS STORE
import { useSelector, useDispatch } from 'react-redux'

export default function Comment(props) {

  dayjs.locale('fr')

  const selectToken = state => state.token
  const token = useSelector(selectToken)
     
  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // State permettant d'afficher l'icône correspondant à l'état de like (vrai ou faux)
  // Positionné à la valeur d'origine du comment
  // On vérifie aussi si le comment est déjà liké par l'utilisateur lui-même
  const [commentLikes, setCommentLikes] = useState({
    nbLikes : props.comment.nbLikes,
    iLike : props.comment.iLike
  })

  function SendReport(commentId) {
    let nextPage ="/Report/" + props.postpk + "/" + commentId
    navigate(nextPage)
  }

  function DateFromNow(item) {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(item.commentTimestamp,"YYYY-MM-DD HH:mm:ss").fromNow())
  }

  function UserAvatar(item) {
    if (item.authorAvatarurl) {
      return (
        <Avatar
          src={item.authorAvatarurl}  
          sx={{ width: 30, height: 30, marginRight : 1 }}
        />
      )
    } else {
      return (
        <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 30, marginRight : 1  }} />
      );
    }
  };

  function UserGlobalBadge(item) {
    if (item.authorGlobalBadge) {
    return (
      <Avatar
      src={item.authorGlobalBadge}  
      sx={{ width: 15, height: 15, marginRight : 1  }}
    />
      )
    } else {
    return null;
    }
  };

  function ReportButton(item) {
    return(
      <Button size="large"
        onClick={() => SendReport(item.pk)}
      >
        <ReportProblemIcon/>
      </Button>
    )
  }

  function ToggleLike() {
    if (token) {
      let fetchArgument = {
        'commentLikeAuthor' : myProfile.pk, //Utilisé pour que le champs soit présent et avec un bon format, mais cet id sera écrasé par le userid dans l'API
        'commentLikeComment' : props.comment.pk
      }
      if (props.comment.iLike) {
        // Si l'utilisateur aime  déjà : c'est un like à supprimer
        setCommentLikes(prevState => ({...prevState,
          nbLikes : prevState.nbLikes - 1,
          iLike : false
        }))
      } else {
        // Si l'utilisateur n'aime pas déjà : c'est un like à ajouter
        setCommentLikes(prevState => ({...prevState,
          nbLikes : prevState.nbLikes + 1,
          iLike : true
        }))
      }
      PostFunction({fetchTarget : 'toggleCommentLike', fetchArgument : fetchArgument, token : token})
      .then(response => {
        if(response.fetchStatus === 'Ok') {
          console.log('Comment.js -> Fin chargement API toggleCommentLike')
        } else {
          console.log('Comment.js -> Chargement API toggleCommentLike en erreur')
          let errorMessage = "Impossible de prendre en compte votre like, vérifiez votre connexion"
          dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
        }
      })
      .then(() => {
        // On lance la fonction de refresh du component parent
        console.log('Comment.js -> Mise à jour du contenu')
        props.refreshFunction()
      })
    } else {
      navigate("/login")
    }
  }

  function LikeButton() {
    return(
      <Box sx={{display : "flex", flexDirection : "row", justifyContent : "space-between"}}>
        <Button size="small"
          onClick={ToggleLike}
        >
          {(commentLikes.iLike ?
          <ThumbUpIcon  color="secondary" sx={{marginRight : 1}}/>
          :
          <ThumbUpIcon  color="primary" sx={{marginRight : 1}}/>
          )}
          {commentLikes.nbLikes}
        </Button>
      </Box>
    )
  }

  return(
    <Box display="flex" flexDirection="column" sx={{marginLeft : 3, paddingTop :2}}>
      <Box sx={{display : "flex", flexDirection : "column", backgroundColor : "white", borderRadius : 3}} wrap = "wrap">
        <Paper
          elevation={3}
          sx={{
            backgroundColor : (props.comment.authorIsHarbor ? "#fdf0e9" : "white"),
            border : (props.comment.authorIsHarbor ? 2 : 0),
            borderColor : (props.comment.authorIsHarbor ? "red" : "none")
              }}
        >
          <Box sx={{display:"flex", flexDirection:"row", alignItems : "center", marginBottom : 1, paddingTop : 1, paddingLeft : 1}}>
            {UserAvatar(props.comment)}
            <Link component={RouterLink} to={"/OtherUserProfile/" + props.comment.commentAuthor} variant="subtitle2" color={(props.comment.authorIsHarbor ? "error" : "primary")} underline="hover" sx={{ marginRight : 1}}>
              {props.comment.authorNickname}
            </Link>
            {UserGlobalBadge(props.comment)}
            <Box sx={{flex : 1}}/>
            {ReportButton(props.comment)}
          </Box>
          <Typography
            fontSize={13}
            color="text.primary"
            wrap = "wrap"
            sx={{paddingBottom : 1, paddingLeft : 1}}
          >
            {props.comment.commentText}
          </Typography>
        </Paper>
      </Box>
      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", marginLeft : 2, marginRight : 5, marginTop : 1}}>
        <Typography
          fontSize={13}
          color="text.primary"
        >
          {DateFromNow(props.comment)} 
        </Typography>
      </Box>
      {LikeButton()}
    </Box>
  )

}
