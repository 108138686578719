import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Post from './Post'
import GetFunction from '../API/getFunction';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import SailingIcon from '@mui/icons-material/Sailing';
import Fade from '@mui/material/Fade';


export default function NewsFeed() {
  // Connexion au store REDUX
  const newsFeedContent = useSelector(state => state.newsFeedContent)
  const token = useSelector(state => state.token)

  const dispatch = useDispatch()

  // State du contennu
  const [feedList, setFeedList] = useState()

  // pull-to-refresh
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Hook qui contruit le contenu du fil à chaque fois que le contenu du newsFeed change
  // (améliore les performances : évite de recalculer le contenu du fil à chaque render,
  // car il y a bcp de render avec le PullToRefresh)
  useEffect(() => {
    if (newsFeedContent.length>=1) {
      setFeedList(newsFeedContent.map((item) => 
            <Post
              key={item.pk}
              postScreen="NewsFeed"
              post={item}
              refreshFunction={RefreshNewsFeed}
            />
      ))
    } else {
      setFeedList(
        <Box sx={{marginTop: 8}}>
          <Typography align="center" variant="body2" >
            Il n'y a pas de publication dans les lieux que vous suivez...<p/>
            <SailingIcon color="primary" sx={{ fontSize: 100 }} />
            <p/>... soyez le premier à publier !
          </Typography>
        </Box>
      )
    }
  }, [newsFeedContent])

  const RefreshNewsFeed = () => {
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
    .then((response) => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if (response.fetchStatus === 'Ok') {
        console.log('NewsFeed.js -> Chargement newsFeedContent dans le state Redux')
        dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
      } else {
        console.log('NewsFeed.js -> Erreur dans le fetch newsFeedContent')
      }
    })
  };

  // 
  // GESTION DU PULL-TO-REFRESH
  //

  // Création des 3 fonctions des listeners

  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
    console.log('PULLSTART')
    console.log(screenY)
  };

  const pull = (e) => {
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPoint < screenY ? Math.abs(screenY - startPoint) : 0);
    setPullChange(pullLength);
    console.log('PULL')
    console.log(screenY)
    console.log('->pullLength  :')
    console.log(pullLength)
  };

  const endPull = (e) => {
    if (pullChange > 220) {
      RefreshNewsFeed()
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
    console.log('ENDPULL')
    console.log('->pullChange  :')
    console.log(pullChange)
  };

  // Déclaration des listeners
  useEffect(() => {
    window.addEventListener("touchstart", pullStart); // L'écran est touché
    window.addEventListener("touchmove", pull);       // Mouvement du doigt
    window.addEventListener("touchend", endPull);     // L'écran est lâché
    return () => {  // Déclaration qui permet de supprimer les listeners quand le component est unmounted
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });

  function FeedList() {
    return (
      // Le Fade sert à matérialiser le passage d'un feed à un autre
      <Fade in={true} timeout={500}>
        <Box sx={{display : "flex", flexDirection : "column", backgroundColor : "white"}} width="100%" height="100%"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
          <Box sx={{height : 70}}/>
          <Box sx={{ flex : 1, paddingBottom : 2}} overflow = "auto">
            <Container sx={{display : "flex", height : Math.min(pullChange,50+Math.max(0,pullChange-50)/5)}}>
              <Box sx={{ flex : 1}}/>
              {(isRefreshing || pullChange > 100) ? <CircularProgress/>:null}
              <Box sx={{ flex : 1}}/>
            </Container>
            {feedList}
          </Box>
        </Box>
      </Fade>
    )
  }

  return(
    FeedList()
  );
}