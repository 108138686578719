import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Typography } from '@mui/material';

export default function Tutorial4(props) {

  function PreviousButton() {
    return (
      <Button
        onClick={() => props.goToTutorialPageFunction({page : "Page 3"})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateBeforeIcon />
      </Button>
    );
  }

  function NextButton() {
    return (
      <Button
        onClick={() => props.goToTutorialPageFunction({page : "Page 5"})}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        <NavigateNextIcon />
      </Button>
    );
  }

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography color={"primary"} paragraph={true}>
          Dans votre profil, retrouvez votre point et votre statut. Ils vous permettent de gagner des badges spécifiques sur vos publications, et d'avoir un icône personnalisé sur la carte !
          </Typography>
          <Typography color={"primary"}  paragraph={true}>
          Soyez actifs pour gagner des points et gagner des récompenses en atteignant de nouveaux objectifs ! Retrouvez vos points et votre statut dans votre page Profil.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {PreviousButton()}
        {NextButton()}
      </DialogActions>
    </Dialog>
  );
}