import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material';

// DECLARATION REDUX
import { useSelector } from 'react-redux'

export default function Contact() {

  const navigate = useNavigate()

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)
  
  const selectChatHeadersArray = state => state.chatHeadersArray
  const chatHeadersArray = useSelector(selectChatHeadersArray)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  let bolUserId //L'ID de l'utilisateur Bol est différent dans les baseq de données DEV et PROD
  if (process.env.NODE_ENV === "production" || process.env.REACT_APP_ENV === "STAGING") {
    bolUserId = 63
  } else {
    bolUserId = 1
  }

  function ChatButton() {
    return (
      <Button
        onClick={ChatWithBol}
        variant="contained"
        endIcon={<EditIcon />}
        sx={{ mt: 3, mb: 2 }}
        disabled={(!token)}
      >
        Ecrire à Bol         
      </Button>
    );
  }

  function ContactScreen() {
    if (myProfile) {
      return (
        <Box backgroundColor = "white" sx={{margin : 1, padding : 2, display : "flex", flexDirection : "column"}}>
          <p/>
          <Typography variant={"h6"} color={"primary"} fontWeight={'bold'} textAlign={'center'}>
            Nous contacter
          </Typography>
          <p/>
          <Typography variant={"body1"} color={"black"} textAlign={'justify'}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
          <p/>
          <Box sx={{justifyContent : "center"}}>
            {ChatButton()}
          </Box>
        </Box>
      );
    } else return null
  }

  function ChatWithBol() {
    let userIndexInSubscription = chatHeadersArray.findIndex(item => (item.chatroomsubscriptions[0].subscriptionUser === bolUserId))
    let existingChatroom = chatHeadersArray[userIndexInSubscription].chatroomsubscriptions[0].subscriptionChatroom
    let nextPage = "/Chat/" + existingChatroom + "/" + bolUserId
    navigate (nextPage)
  }

  return(
    <ContactScreen/>
  )
}