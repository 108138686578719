import { useState, useEffect } from 'react';

import GetFunction from '../API/getFunction'
import DeleteFunction from '../API/deleteFunction'

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import GroupsIcon from '@mui/icons-material/Groups';
import CommentIcon from '@mui/icons-material/Comment';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import BackButton from '../Navigation/BackButton';


// Déclaration REDUX
import { useSelector, useDispatch } from 'react-redux'

// Déclaration reat-router
import { useNavigate } from 'react-router-dom'

export default function SubscribedSpots() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Connexion au markerArray du store
  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectSubscriptionsArray = state => state.subscriptionsArray
  const subscriptionsArray = useSelector(selectSubscriptionsArray)

  const [subscribedMarkersArray, setSubscribedMarkersArray] = useState([])

  function SetSubscribedMarkersArray() {
    let newSubscribedMarkersArray = markersArray.filter((marker) => (subscriptionsArray.findIndex((id) => marker.pk === id.subscriptionSpot) !== -1))
    let nextSubscribedMarkersArray = newSubscribedMarkersArray.map(item => {
      let newItem
      if (item.spotType === "Port") {
        newItem = {...item, markerImgZoom : 15}
      } else if (item.spotType === "Mouillage") {
        newItem = {...item, markerImgZoom : 12}
      }
      return newItem
    });
    setSubscribedMarkersArray(nextSubscribedMarkersArray)
  }

  useEffect(() => {
    console.log('SubscribedSpots.js -> useEffect')
    SetSubscribedMarkersArray()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[subscriptionsArray]
  );   

  function LoadNewsFeed() {
    GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
    .then((response) => {
      if (response.fetchStatus === 'Ok') {
        console.log('SubscribedSpots.js -> Chargement nouveau newsFeedContent dans le state Redux')
        dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
      } else {
        console.log('SubscribedSpots.js -> Erreur dans le fetch newsFeedContent')
      }
    })
  }

  function Unsubscribe(marker) {
    console.log('SubscribedSpots.js -> unsubscribe')
    // On va chercher dans la liste des subscriptions la clé primaire de la subscription à supprimer
    let markerPk = subscriptionsArray[subscriptionsArray.findIndex(id => (marker.pk === id.subscriptionSpot))].pk 
    let fetchArgument = {
      'pk' : markerPk
      }
    DeleteFunction({fetchTarget : 'deleteSubscription', fetchArgument : fetchArgument, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('SubscribedSpots.js -> Fin chargement API toggleSubscription')
        console.log('SubscribedSpots.js -> Fetch du nouvel état de subscriptionArray')
        GetFunction({fetchTarget : 'subscriptionsArray', fetchArgument : null, token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('SubscribedSpots.js -> Chargement nouveau subscriptionsArray dans le state Redux')
            dispatch({ type : "LOAD_SUBSCRIPTIONS_ARRAY", payload:response.data})
          } else {
            console.log('SubscribedSpots.js -> Impossible de charger le nouveau subscriptionsArray')
            let errorMessage = "Impossible d'afficher votre désabonnement, vérifiez votre connexion"
            dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
          }
        })
        // Récupération du nouveau newsFeed
        GetFunction({fetchTarget : 'newsFeedContent',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapPage.js -> Chargement newsFeedContent dans le state Redux')
            dispatch({ type : "LOAD_NEWS_FEED_CONTENT", payload:response.data})
          } else {
            console.log('MapPage.js -> Erreur dans le fetch newsFeedContent')
          }
        })
      } else {
        console.log('SubscribedSpots.js -> Chargement API toggleSubscription en erreur')
        let errorMessage = "Impossible de prendre en compte votre désabonnement, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
    .then(LoadNewsFeed)
  }


  function MarkerDetailCardContent(item) {
    return(
      <Paper elevation={3} key = {item.pk.toString()}>
        <Box sx={{display:"flex", flexDirection:"row", paddingRight : {xs : 2, md : 5}, marginTop : 2, marginBottom : 2, alignItems:"center", backgroundColor : "white", borderRadius : 2}}>
          <Box
            onClick={() => {navigate("/Feed/" + item.pk)}}
            component="img"
            sx={{
              display: 'inline-flex',
              maxHeight: "10vh",
              maxWidth: "10vh",
              marginRight : 2
            }}
            src={"https://maps.googleapis.com/maps/api/staticmap?center=" + item.spotLatitude + "," + item.spotLongitude + "&zoom=" + item.markerImgZoom + "&size=400x400&maptype=satellite&key=AIzaSyATHwwDt6IBo65JOtA0bxsJ7G8r7lk4Fdk"}
          />
          <Box sx={{flex:8, flexDirection:"column"}} onClick={() => {navigate("/Feed/" + item.pk)}}>
            <Typography variant="body1" fontWeight="bold" color={"primary"}>
              {item.spotName}
            </Typography>
            <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
              <GroupsIcon color="primary" sx={{marginRight : 1}}/>
              <Typography  variant="body1" component="div" color="primary" sx={{marginRight : 2}}>
                {item.nbSubscriptions}
              </Typography>
              <CommentIcon  color="primary" sx={{marginRight : 1}}/>
              <Typography  variant="body1" component="div" color="primary">
                {item.nbPosts}
              </Typography>
            </Box>
          </Box>
          <Box sx={{flex:1}}>
            <IconButton onClick={() => navigate("/MapPage" , {state : {zoomToSearch : item}})}>
              <LocationOnIcon color="primary" sx={{ fontSize: 30 }}/>
            </IconButton>
            <IconButton onClick={() => {Unsubscribe(item)}}>
              <FavoriteIcon color="secondary" sx={{ fontSize: 30 }}/>
            </IconButton>
          </Box>
        </Box>
      </Paper>
    )
  }

  if (subscribedMarkersArray.length >= 1) {
    return(
      <Container>
              <BackButton/>
              <Box sx={{height : 70, backgroundColor : "white"}}/>
          {subscribedMarkersArray.map(item => 
              <Zoom in={true} key = {item.pk.toString()}>
                {MarkerDetailCardContent(item)}
              </Zoom>
        )}
    </Container>
    )
  } else {
    return(
        <Container>
                <BackButton/>
                <Box sx={{height : 70, backgroundColor : "white"}}/>
          <Typography component="h1" variant="h5">
            Vous n'êtes pas encore abonné à un lieu.
          </Typography>
        </Container>
    )
  }
}