export default function GetFunction(props) {

  let urlBase = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:8000'
  : 'https://api.radioponton.fr'

  let headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Token ' + props.token,
  }

  let url = ""

  console.log('GetFunction '+props.fetchTarget+' -> Lancement Fetch')
  switch (props.fetchTarget) {
    case 'markersArray':
      url = urlBase + '/spot/'
      headers = {     // Requête publique : on supprime l'authentification par token
        'Content-Type': 'application/json',
      }
      break;
    case 'userPositionsArray':
      url = urlBase + '/userpositionsarray/'
      headers = {     // Requête publique : on supprime l'authentification par token
        'Content-Type': 'application/json',
      }
      break;
      case 'subscriptionsArray':
      url = urlBase + '/subscription/'
      break; 
    case 'reportMarksArray':
      url = urlBase + '/reportmarksarray/'
      headers = {     // Requête publique : on supprime l'authentification par token
        'Content-Type': 'application/json',
      }
      break; 
    case 'spotFeedContent':
      url = urlBase + '/spotpost/'
      headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + props.token,
        'spotId': props.fetchArgument
      }
      break;
    case 'spotFeedPreviewContent':
      url = urlBase + '/spotpostpreview/'
      headers = {     // Requête publique : on supprime l'authentification par token
        'Content-Type': 'application/json',
        'spotId': props.fetchArgument
      }
      break;
    case 'spotSubscriptionsCount':
      url = urlBase + '/spotsubscriptioncount/'
      headers = {     // Requête publique : on supprime l'authentification par token
        'Content-Type': 'application/json',
        'spotId': props.fetchArgument
      }
      break;
    case 'newsFeedContent':
      url = urlBase + '/newsfeed/'
      break;
    case 'visibleSpotsFeed':
      // L'url est soit celle de la première page, soit celui envoyé en argument
      url = (props.fetchArgument.nextPageUrl ? props.fetchArgument.nextPageUrl : urlBase + '/visiblespotsfeed/?page=1')
      headers = {     // Requête publique : on supprime l'authentification par token
        'Content-Type': 'application/json',
        'spotList': props.fetchArgument.visibleSpotsIDs
      }
      break;
    case 'getPostWithComments':
      url = urlBase + '/postwithcomments/'
      headers = {
        ...headers,
        'postId': props.fetchArgument
      }
      break;
    case 'chatHeadersArray':
      url = urlBase + '/chatroomheader/'
      break;
    case 'getChatMessages':
      url = urlBase + '/chatroommessage/'
      headers = {
        ...headers,
        'chatroomId': props.fetchArgument
      }
      break;
    case 'getUserProfile':
      url = urlBase + '/userprofile/'
      break;
    case 'getAccount':
      url = urlBase + '/account/'
      break;
    case 'getOtherUserProfile':
      url = urlBase + '/otheruserprofile/'
      headers = {
        ...headers,
        'userId': props.fetchArgument
      }
      break;
    case 'getFeedMembers':
      url = urlBase + '/feedmember/'
      headers = {
        ...headers,
        'spotId': props.fetchArgument
      }
      break;
    case 'notificationArray':
      url = urlBase + '/notification/'
      break;
    case 'checkNickname':
      url = urlBase + '/checknickname/'
      headers = {
        ...headers,
        'userNickname': props.fetchArgument
      }
      break;
    case 'checkEmail':
      url = urlBase + '/checkemail/'
      headers = {
        ...headers,
        'email': props.fetchArgument
      }
      break;
      
    default:
      console.log('GetFunction -> '+props.fetchTarget+' est un fetchTarget inconnu, aucun fetch lancé')
  }

  // Exécution du fetch
  return fetch(url, {
    method: 'GET',
    headers: headers
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error('Response pas Ok')
    } else {
      return response.json()
    }
  })
  .then((response) => {
    console.log('GetFunction '+props.fetchTarget+' -> Fetch terminé OK')
    return({fetchStatus:'Ok',data:response})
  })
  .catch((error) => {
    if (error === 'Response pas Ok ') {
      console.log('GetFunction '+props.fetchTarget+' -> Réponse non attendue du fetch')
    } else {
      console.log('GetFunction '+props.fetchTarget+' -> Erreur pendant le Fetch')
    }
    return({fetchStatus:'Error'})
  })
}